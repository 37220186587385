// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { SettingsContext } from '../../contexts';
import { useCartItems, useGlobalDictionaryQuery, useProtectionProducts } from '../../hooks/index';
import {
  DesktopView,
  MobileView,
  colors,
  desktopBreakpoint,
  desktopVW,
  mobileVW,
  zIndex,
} from '../../styles/index';
import Icons from '../../utils/Icons';
import formatPrice from '../../utils/formatPrice';
import ProductFormSkylt from './ProductFormSkylt';
import Image from 'gatsby-image';
import { useAddItemToCart, useAddToCartTracking, useProductViewTracking } from '../../hooks';
import { decode } from 'shopify-gid';

interface Props {
  shopifyData: {
    price: string;
    compareAtPrice: string;
    outOfStock: boolean;
  };
  slug: any;
  skyltChecked: any;
  setSkyltChecked: any;
  selectedVariant: {
    price: string;
    title: string;
    outOfStock: string;
    compare_at_price: string;
    id: string;
  };
  setSelectedVariantState: any;
  productTitle: string;
  updateVariants: any;
  quantity: any;
  setQuantity: any;
  category: string;
  allowAddProtection: boolean;
  protectionProduct: any;
  oversell: boolean;
  delivery: 'slow' | 'quick';
  variantItemForOption: any;
  imageFromVariantItem: any;
  titleVariantItem: string;
  titleProtectionVariantItem: string;
  protectionExtraItem: any;
  getExtraItem?: Function;
  getProtectionfabric?: Function;
}

export default function ProductForm(props: Props) {
  const {
    shopifyData,
    skyltChecked,
    setSkyltChecked,
    selectedVariant,
    setSelectedVariantState,
    productTitle,
    updateVariants,
    quantity,
    setQuantity,
    category,
    allowAddProtection,
    protectionProduct,
    slug,
    oversell,
    delivery,
    variantItemForOption,
    imageFromVariantItem,
    protectionExtraItem,
    titleVariantItem,
    titleProtectionVariantItem,
    getExtraItem,
    getProtectionfabric,
  } = props;
  const cart = useCartItems();
  const {
    isCartOpen,
    setIsCartOpen,
    purchaseAllowed,
    allProductShopify,
    setMaxQuantityCurrentVariant,
    setQuantityCurrentVariant,
  }: {
    isCartOpen: any;
    setIsCartOpen: any;
    purchaseAllowed: any;
    allProductShopify: any;
    setMaxQuantityCurrentVariant: any;
    setQuantityCurrentVariant: any;
  } = useContext(SettingsContext);
  const dictionary = useGlobalDictionaryQuery();
  const protectionProducts = useProtectionProducts();
  const [desktopSelectOpen, setdesktopSelectOpen] = useState(false);
  const [desktopSelectState, setDesktopSelectState] = useState(selectedVariant.title);
  const [lengthOption, setLengthOption] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [maxQuantityVariant, setMaxQuantityVariant] = useState(0);
  const [disableButtonQuantityPlus, setDisableButtonQuantityPlus] = useState(false);
  const [selectOptionVariant, setSelectOptionVariant] = useState(null);
  const [addVariantItem, setAddVariantItem] = useState(false);
  const [addProtectionVariantItem, setAddProtectionVariantItem] = useState(false);
  const selectSpring = useSpring({
    transform: desktopSelectOpen ? 'translateY(0%)' : 'translateY(-102%)',
  });
  const [typeProtectionDefault, setTypeProtectionDefault] = useState(true);
  const [hoverExtraItem, setHoverExtraItem] = useState(null);
  const handleSelectVariant = e => {
    setSelectedVariantState(...shopifyData.variants.filter(({ title }) => title === e));
  };

  const handleSizeclickDesktop = sizeTitle => {
    setSelectedVariantState(...shopifyData.variants.filter(({ title }) => title === sizeTitle));
    setDesktopSelectState(sizeTitle);
    setdesktopSelectOpen(false);
  };

  const addItemToCart = useAddItemToCart();

  const priceExtraProtection = (item: any) => {
    if (item) {
      let price = protectionProducts[item?.protectionExtraItem]?.price?.amount;
      if (price) {
        return price;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (
      protectionProduct?.title != 'Small' &&
      protectionProduct?.title != 'Medium' &&
      protectionProduct?.title != 'Large'
    ) {
      setTypeProtectionDefault(false);
    } else {
      setTypeProtectionDefault(true);
    }
  }, [protectionProduct]);

  const handleAddToCart = async () => {
    // If skylt is selected, pass {key: "skylt", value: "true"} as customAttributes

    if (allowAddProtection && variantItemForOption != null) {
      await addItemToCart(selectedVariant.id, quantity, [
        {
          key: `Extra protection - ${protectionProduct?.title}`,
          value: skyltChecked ? 'added' : 'not added',
        },
        {
          key: `Extra item ${
            selectOptionVariant
              ? ` (${titleVariantItem?.toLowerCase()}) - ${selectOptionVariant.title}`
              : ''
          }`,
          value: selectOptionVariant != null ? 'added' : 'not added',
        },
        {
          key: `Extra fabric protection for ${titleVariantItem?.toLowerCase()} - ${
            selectOptionVariant?.protectionExtraItem
          }`,
          value: addProtectionVariantItem ? 'added' : 'not added',
        },
      ]);
    } else if (allowAddProtection) {
      await addItemToCart(selectedVariant.id, quantity, [
        {
          key: `Extra protection - ${protectionProduct?.title}`,
          value: skyltChecked ? 'added' : 'not added',
        },
      ]);
    } else if (variantItemForOption != null) {
      let extraItemVariantId = decode(selectOptionVariant?.shopifyData?.variants[0]?.id);
      await addItemToCart(selectedVariant.id, quantity, [
        {
          key: `Extra item ${
            selectOptionVariant
              ? `(${titleVariantItem?.toLowerCase()}) - ${selectOptionVariant.title}`
              : ''
          }`,
          value: selectOptionVariant != null ? 'added' : 'not added',
        },
        {
          key: `Extra fabric protection for ${titleVariantItem?.toLowerCase()} - ${
            selectOptionVariant?.protectionExtraItem
          }`,
          value: addProtectionVariantItem ? 'added' : 'not added',
        },
      ]);
    } else {
      await addItemToCart(selectedVariant.id, quantity);
    }

    useAddToCartTracking(
      selectedVariant.id,
      `${productTitle} - ${selectedVariant.title}`,
      parseFloat(selectedVariant.price),
      location,
      quantity,
      category
    );
    if (!isCartOpen) {
      setIsCartOpen(true);
    }
  };

  const calculatePrice = () => {
    let total = Number(selectedVariant.price);
    if (skyltChecked) total = total + Number(protectionProduct.price.amount);
    if (selectOptionVariant != null)
      total = total + Number(selectOptionVariant?.shopifyData?.variants[0]?.price);
    if (addProtectionVariantItem) total = total + Number(priceExtraProtection(selectOptionVariant));

    return total * quantity;
  };

  useEffect(() => {
    setQuantity(1);
  }, [selectedVariant]);

  useEffect(() => {
    if (selectedVariant.outOfStock !== null && selectedVariant.id) {
      useProductViewTracking(
        selectedVariant.id,
        `${productTitle} - ${selectedVariant.title}`,
        parseFloat(selectedVariant.price),
        location,
        category
      );
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (cartItems.length === 0 && cart.length > 0) {
      setCartItems(cart);
    }
  }, [cart]);

  useEffect(() => {
    let checkMaxProductQuantity = '';
    if (allProductShopify?.length > 0) {
      for (let i = 0; i < allProductShopify.length; i++) {
        allProductShopify[i].node.variants.edges.map(item => {
          if (selectedVariant.sku === item.node.sku) {
            checkMaxProductQuantity = item;
          }
        });
      }
    }
    if (checkMaxProductQuantity != '') {
      setMaxQuantityVariant(checkMaxProductQuantity?.node?.quantityAvailable);
      setMaxQuantityCurrentVariant(checkMaxProductQuantity?.node?.quantityAvailable);
    }
  }, [allProductShopify, selectedVariant]);

  useEffect(() => {
    let checkTotalQuantity = [];
    checkTotalQuantity = cartItems.filter(item => item?.variant?.sku === selectedVariant?.sku);
    if (checkTotalQuantity.length > 0) {
      setTotalQuantity(checkTotalQuantity[0].quantity + quantity);
      setQuantityCurrentVariant(checkTotalQuantity[0].quantity + quantity);
    } else {
      setTotalQuantity(quantity);
      setQuantityCurrentVariant(quantity);
    }
  }, [cartItems, selectedVariant, quantity]);

  const selectVariantItem = (item: any) => {
    getExtraItem(item);
    setAddVariantItem(true);
    setSelectOptionVariant(item);
    imageFromVariantItem(item);
  };

  const ongChangeStatusAddVariantItem = () => {
    if (addVariantItem) {
      setSelectOptionVariant(null);
      imageFromVariantItem(null);
      setAddProtectionVariantItem(false);
      getExtraItem(null);
    }
    setAddVariantItem(!addVariantItem);
  };

  return (
    <Wrapper>
      <MobileView>
        <SizeSelect style={{ height: lengthOption > 28 ? mobileVW(60) : 'auto' }}>
          <label htmlFor="sizes">{dictionary?.pdpSizeText}</label>
          <BoxOptionDesktop>
            {shopifyData.variants.map(({ title }) => {
              if (title.length > lengthOption) {
                setLengthOption(title.length);
              }
              return (
                <OptionContent key={title}>
                  {selectedVariant.title == title ? (
                    <OptionChildMobileSelected
                      onClick={() => handleSelectVariant(title)}
                      key={title}
                    >
                      {title}
                    </OptionChildMobileSelected>
                  ) : (
                    <OptionChildMobile onClick={() => handleSelectVariant(title)} key={title}>
                      {title}
                    </OptionChildMobile>
                  )}
                </OptionContent>
              );
            })}
          </BoxOptionDesktop>
        </SizeSelect>
      </MobileView>
      <DesktopView>
        <SizeSelect onMouseLeave={() => setTimeout(() => setdesktopSelectOpen(false), 150)}>
          <label htmlFor="sizes">{dictionary?.pdpSizeText}</label>
          <BoxOptionDesktop>
            {shopifyData.variants.map(({ title }) => (
              <OptionContent key={title}>
                {title == desktopSelectState ? (
                  <OptionChildDesktopSelected
                    key={title}
                    onClick={() => handleSizeclickDesktop(title)}
                  >
                    <p>{title}</p>
                  </OptionChildDesktopSelected>
                ) : (
                  <OptionChildDesktop key={title} onClick={() => handleSizeclickDesktop(title)}>
                    <p>{title}</p>
                  </OptionChildDesktop>
                )}
              </OptionContent>
            ))}
          </BoxOptionDesktop>
        </SizeSelect>
      </DesktopView>

      {variantItemForOption && (
        <VariantItemWrapperWrapper>
          <VariantItemWrapperHeading>
            <VariantItemHeader onClick={() => ongChangeStatusAddVariantItem()}>
              <BoxVariantItemHeader check={addVariantItem} />
              Add Extra {titleVariantItem}
            </VariantItemHeader>
            {selectOptionVariant && (
              <VariantItemHeader check={addVariantItem}>
                +{formatPrice(selectOptionVariant?.shopifyData?.variants[0]?.price, '€')}
              </VariantItemHeader>
            )}
          </VariantItemWrapperHeading>
          <VariantItemWrapper check={addVariantItem}>
            {variantItemForOption?.map((item: any, i: number) => (
              <SelectOptionWrapper
                key={i}
                onClick={() => selectVariantItem(item)}
                onMouseEnter={() => setHoverExtraItem(item?.titleToShow)}
                onMouseLeave={() => setHoverExtraItem('')}
              >
                {item?.titleToShow === selectOptionVariant?.titleToShow ? (
                  <SelectOptionValueSelected fluid={item?.thumbnailImages[0].fluid} />
                ) : (
                  <SelectOptionValue fluid={item?.thumbnailImages[0].fluid} />
                )}
                {hoverExtraItem === item?.titleToShow && (
                  <ExtraItemTitle> {hoverExtraItem}</ExtraItemTitle>
                )}
              </SelectOptionWrapper>
            ))}
          </VariantItemWrapper>
          {selectOptionVariant?.protectionExtraItem &&
            selectOptionVariant?.protectionExtraItem != 'None' && (
              <VariantItemProtection check={addVariantItem}>
                <VariantItemProtectionLeft>
                  <VariantItemProtectionLeftInner
                    onClick={() => {
                      if (addVariantItem) {
                        getProtectionfabric(!addProtectionVariantItem);
                        setAddProtectionVariantItem(!addProtectionVariantItem);
                      }
                    }}
                  >
                    <BoxVariantItemProtection check={addProtectionVariantItem} />
                    Add {titleProtectionVariantItem}
                  </VariantItemProtectionLeftInner>
                  <SkyltTooltipTrigger>
                    ?
                    <SkyltTooltip>
                      An invisible matte layer is applied to protect the fabric against water, dirt
                      and circles
                    </SkyltTooltip>
                  </SkyltTooltipTrigger>
                </VariantItemProtectionLeft>
                <div>
                  {addProtectionVariantItem
                    ? '+' + formatPrice(priceExtraProtection(selectOptionVariant), '€')
                    : null}
                </div>
              </VariantItemProtection>
            )}
        </VariantItemWrapperWrapper>
      )}
      <AddToCartWrapper allowAddProtection={allowAddProtection}>
        {allowAddProtection && (
          <ProductFormSkylt
            typeDefault={typeProtectionDefault}
            skyltChecked={skyltChecked}
            setSkyltChecked={setSkyltChecked}
            price={Number(protectionProduct?.price?.amount)}
          />
        )}
        <QuantityBlock>{dictionary?.pdpQuantityText}</QuantityBlock>
        <QuantityCounterBlock>
          <Counter onMouseEnter={() => updateVariants()}>
            <Button
              type="button"
              aria-label="decrease quantity"
              onClick={() => (quantity > 1 ? setQuantity(quantity - 1) : null)}
            >
              -
            </Button>
            <Count>{quantity}</Count>
            <Button
              type="button"
              disabled={!!(totalQuantity >= maxQuantityVariant && oversell === false)}
              aria-label="increase quantity"
              onClick={() => setQuantity(quantity + 1)}
            >
              +
            </Button>
          </Counter>
        </QuantityCounterBlock>
        <AddToCartBlock>
          {purchaseAllowed &&
            (purchaseAllowed?.allowed ? (
              <button type="button" aria-label="add to cart" onClick={() => handleAddToCart()}>
                {`${formatPrice(calculatePrice(), '€')}
                   - ${dictionary?.addToCartText}`}
              </button>
            ) : (
              <DeniedMsg>{dictionary?.noPurchaseMessage}</DeniedMsg>
            ))}
        </AddToCartBlock>
      </AddToCartWrapper>
    </Wrapper>
  );
}

const VariantItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  margin-bottom: ${mobileVW(15)};
  opacity: ${({ check }) => (check ? '1' : '0.4')};
  padding-left: ${mobileVW(20)};
  ${desktopBreakpoint} {
    padding-left: ${desktopVW(20)};
    margin-bottom: ${desktopVW(30)};
  }
`;
const SelectOptionWrapper = styled.div`
  display: flex;
  margin-bottom: ${desktopVW(5)};
  cursor: pointer;
  position: relative;
`;
const SelectOptionValue = styled(Image)`
  display: flex;
  width: ${mobileVW(60)};
  height: ${mobileVW(60)};
  margin-right: ${mobileVW(15)};
  border-radius: ${mobileVW(60)};
  ${desktopBreakpoint} {
    width: ${desktopVW(65)};
    height: ${desktopVW(65)};
    border-radius: ${desktopVW(65)};
    margin-right: ${desktopVW(15)};
  }
`;
const SelectOptionValueSelected = styled(Image)`
  display: flex;
  width: ${mobileVW(60)};
  height: ${mobileVW(60)};
  border-radius: ${mobileVW(60)};
  background-color: ${colors.black};
  margin-right: ${mobileVW(15)};
  border: ${desktopVW(2)} solid #262525;
  ${desktopBreakpoint} {
    width: ${desktopVW(65)};
    height: ${desktopVW(65)};
    border-radius: ${desktopVW(65)};
    margin-right: ${desktopVW(15)};
  }
`;
const VariantItemWrapperWrapper = styled.div``;
const VariantItemWrapperHeading = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  margin-top: -${mobileVW(20)};
  margin-bottom: ${mobileVW(20)};
  ${desktopBreakpoint} {
    margin-top: -${desktopVW(15)};
    margin-bottom: ${desktopVW(20)};
  }
`;

const BoxVariantItemHeader = styled.div`
  display: flex;
  width: ${mobileVW(14)};
  height: ${mobileVW(14)};
  background-color: ${({ check }) => (check ? colors.black : 'transparent')};
  border: ${mobileVW(1)} solid ${colors.lightGrey};
  border-radius: ${mobileVW(1)};
  margin-right: ${mobileVW(10)};
  cursor: pointer;
  ${desktopBreakpoint} {
    width: ${desktopVW(10)};
    height: ${desktopVW(10)};
    border: ${desktopVW(1)} solid ${colors.lightGrey};
    border-radius: ${desktopVW(1)};
    margin-right: ${desktopVW(10)};
  }
`;
const BoxVariantItemProtection = styled.div`
  display: flex;
  width: ${mobileVW(14)};
  height: ${mobileVW(14)};
  background-color: ${({ check }) => (check ? colors.black : 'transparent')};
  border: ${mobileVW(1)} solid ${colors.lightGrey};
  border-radius: ${mobileVW(1)};
  margin-right: ${mobileVW(10)};
  cursor: pointer;

  ${desktopBreakpoint} {
    width: ${desktopVW(10)};
    height: ${desktopVW(10)};
    background-color: ${({ check }) => (check ? colors.black : 'transparent')};
    border: ${desktopVW(1)} solid ${colors.lightGrey};
    border-radius: ${desktopVW(1)};
    margin-right: ${desktopVW(10)};
  }
`;

const VariantItemHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: ${mobileVW(12)};
  cursor: pointer;
  ${desktopBreakpoint} {
    font-size: ${desktopVW(12)};
  }
`;
const VariantItemProtection = styled.div`
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  display: flex;
  opacity: ${({ check }) => (check ? '1' : '0.4')};
  align-items: center;
  font-size: ${mobileVW(12)};
  margin-bottom: ${mobileVW(12)};
  padding-left: ${mobileVW(17)};
  justify-content: space-between;
  ${desktopBreakpoint} {
    font-size: ${desktopVW(12)};
    padding-left: ${desktopVW(20)};
    margin-bottom: ${desktopVW(30)};
  }
`;
const VariantItemProtectionLeft = styled.div`
  display: flex;
  align-items: center;
`;
const VariantItemProtectionLeftInner = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const VariantItemFooter = styled.div`
  disply: flex;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  cursor: pointer;
  font-size: ${mobileVW(14)};
  margin-bottom: ${mobileVW(10)};
  margin-top: ${mobileVW(10)};
  ${desktopBreakpoint} {
    margin-bottom: ${desktopVW(10)};
    font-size: ${desktopVW(15)};
    margin-top: ${mobileVW(0)};
  }
`;

const ExtraItemTitle = styled.div`
  position: absolute;
  bottom: -${desktopVW(30)};
  z-index: 10;
  color: #6e6b6b;
  font-size: ${desktopVW(13)};
  background-color: #f2efec;
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  padding: ${desktopVW(3)} ${desktopVW(10)} ${desktopVW(4)} ${desktopVW(10)};
  border-radius: ${desktopVW(5)};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: none;
  white-space: nowrap;
  ${desktopBreakpoint} {
    display: flex;
  }
`;

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SizeSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  border-bottom: ${mobileVW(1)} solid ${colors.lightGrey};
  padding-bottom: ${mobileVW(10)};
  margin-bottom: ${mobileVW(30)};
  position: relative;
  z-index: 2;

  label {
    font-size: ${mobileVW(18)};
    margin-bottom: ${mobileVW(10)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
      margin-bottom: ${desktopVW(10)};
    }
  }

  ${desktopBreakpoint} {
    border-bottom: ${desktopVW(1)} solid ${colors.lightGrey};
    padding-bottom: ${desktopVW(10)};
    // padding-right: ${desktopVW(25)};
    margin-bottom: ${desktopVW(20)};
    font-size: ${desktopVW(14)};
  }
`;

const AddToCartWrapper = styled.div`
  width: 100%;
  display: grid;
  z-index: 2;
  grid-template-areas: ${props =>
    props.allowAddProtection
      ? `"skyltBlock skyltBlock" "quantity quantityCounter" "addtoCart addtoCart"`
      : `"quantity quantityCounter" "addtoCart addtoCart"`};
  grid-template-columns: 40% 60%;
  grid-template-rows: ${props =>
    props.allowAddProtection
      ? `${mobileVW(73)} ${mobileVW(90)} ${mobileVW(90)}`
      : `${mobileVW(90)} ${mobileVW(90)}`};
  ${desktopBreakpoint} {
    grid-template-rows: ${props =>
      props.allowAddProtection
        ? `${desktopVW(53)} ${desktopVW(74)} ${desktopVW(74)}`
        : `${desktopVW(74)} ${desktopVW(74)}`};
  }
`;

const OptionChildMobile = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${mobileVW(45)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${mobileVW(10)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  font-size: ${mobileVW(14)};
  border-bottom: ${mobileVW(1)} solid ${colors.lightGrey};
  width: ${mobileVW(175)};
  p {
    pointer-events: none;
  }
`;

const OptionChildMobileSelected = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${mobileVW(45)};
  font-size: ${mobileVW(14)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${mobileVW(10)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  border: ${desktopVW(3)} solid #262525;
  width: ${mobileVW(175)};
  p {
    pointer-events: none;
  }
`;

const OptionChildDesktop = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${desktopVW(40)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${desktopVW(5)};
  width: ${desktopVW(188)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  border-bottom: ${desktopVW(1)} solid ${colors.lightGrey};
  p {
    pointer-events: none;
  }
`;

const OptionChildDesktopSelected = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${desktopVW(40)};
  display: flex;
  width: ${desktopVW(188)};
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${desktopVW(5)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  border: ${desktopVW(2)} solid #262525;
  p {
    pointer-events: none;
  }
`;

const BoxOptionDesktop = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const OptionContent = styled.div`
  margin-bottom: ${desktopVW(5)};
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-top: ${mobileVW(4)};

  ${desktopBreakpoint} {
    right: ${desktopVW(-5)};
    padding-top: 0;
    cursor: pointer;
  }
`;

const QuantityBlock = styled.div`
  grid-area: quantity;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border-left: ${mobileVW(1)} solid ${colors.lightGrey};
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    border-left: ${desktopVW(1)} solid ${colors.lightGrey};
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
  }
`;

const QuantityCounterBlock = styled.div`
  grid-area: quantityCounter;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border-left: ${mobileVW(1)} solid ${colors.lightGrey};
  border-right: ${mobileVW(1)} solid ${colors.lightGrey};
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};

  ${desktopBreakpoint} {
    border-left: ${desktopVW(1)} solid ${colors.lightGrey};
    border-right: ${desktopVW(1)} solid ${colors.lightGrey};
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
    font-size: ${desktopVW(14)};
  }
`;

const AddToCartBlock = styled.div`
  grid-area: addtoCart;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  font-size: ${mobileVW(18)};

  button {
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    color: ${colors.cappuccino};
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    letter-spacing: -0.05em;
    font-size: ${mobileVW(18)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
    }
  }

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;

const Counter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 40%;
  height: 100%;
  cursor: pointer;
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${desktopBreakpoint} {
    width: 10%;
    height: 100%;
  }
`;

const DeniedMsg = styled.div`
  width: 100%;
  height: 100%;
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};
  font-size: ${mobileVW(18)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 ${mobileVW(20)};

  ${desktopBreakpoint} {
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
    font-size: ${desktopVW(14)};
    padding: 0 ${desktopVW(20)};
  }
`;

const SkyltTooltip = styled.span`
  position: absolute;
  background: #efedea;
  visibility: hidden;
  cursor: default;
  font-size: ${mobileVW(13)};
  border: ${mobileVW(1)} solid black;
  bottom: ${mobileVW(11)};
  left: ${mobileVW(11)};
  width: ${mobileVW(270)};
  padding: ${mobileVW(10)};
  transform: translateX(-71%);
  ${desktopBreakpoint} {
    font-size: ${desktopVW(12)};
    border-width: ${desktopVW(1)};
    bottom: ${desktopVW(11)};
    left: ${desktopVW(11)};
    width: ${desktopVW(270)};
    padding: ${desktopVW(10)};
    transform: translateX(-50%);
  }
`;
const SkyltTooltipTrigger = styled.div`
  cursor: pointer;
  border-radius: 50%;
  background: transparent;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: ${mobileVW(1)} solid black;
  margin-left: ${mobileVW(8)};
  height: ${mobileVW(22)};
  width: ${mobileVW(22)};
  z-index: 1;
  ${desktopBreakpoint} {
    border-width: ${desktopVW(1)};
    margin-left: ${desktopVW(8)};
    height: ${desktopVW(22)};
    width: ${desktopVW(22)};
  }
  &:hover ${SkyltTooltip} {
    visibility: visible;
  }
`;
